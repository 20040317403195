import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import API, { optionCode } from "../../../api/configurator";
import {
  ChevronInCircle,
  Close,
  Configurator,
  Logo,
} from "../../partials/Icons";
import { changeQuantity } from "../../../redux/actions/cart";
import {
  addOrder,
  confirmSelection,
  filterStepsData,
  getStepDataFx,
  toggleResultModal,
  submitResult,
  resetConfigurator,
} from "../../../redux/actions/configurator";
import {
  renderGroupValue,
  renderGroupCode,
  getBaseSerial,
  getSerial,
} from "../../partials/ConfiguratorParts";
import { ModalResult } from "./ConfiguratorResult";
import { ModalInConfigurator } from "./ConfiguratorInnerModal";
import { ModalCart } from "./ConfiguratorCart";
import {
  closeResultModal,
  openInnerModal,
  closeConfigurator,
} from "./ConfiguratorAnimations";

type Group = {
  id: number;
  code: string;
  name: string;
};

type ConfiguratorOption = {
  id: string;
  type: "configurator_options";
};

type ConfiguratorSubstep = {
  id: string;
  type: "configurtor_substeps";
};

type ConfiguratorStep = {
  id: string;
  type: "configurator_steps";
  attributes?: {
    configurator_id: string;
    multiple: boolean;
    name: string;
    slug: string;
  };
  relationships?: {
    type: "configurator_steps";
    configurator: {
      links: {
        related: string;
      };
    };
    configurator_options: {
      data: ConfiguratorOption[];
      links: {
        related: string;
      };
    };
    configurator_substeps: {
      data: ConfiguratorSubstep[];
      links: {
        related: string;
      };
    };
  };
};

type JsonData = {
  data: {
    id: string;
    type: string;
    attributes: {
      name: string;
      slug: string;
    };
    relationships: {
      configurator_steps: {
        data: ConfiguratorStep[];
        links: {
          related: string;
        };
      };
    };
  };
  included: ConfiguratorStep[];
  meta: {};
};

type Configurator = {
  id: number;
  stepIndex: 0;
  cartModal: boolean;
  form: boolean;
  groups: {
    group0: Group;
    group1: Group;
    group2: Group;
    group3: Group;
    group4: Group;
    group5: Group;
    group6: Group;
    subGroup: Group;
  };
  codes: {
    group0: string;
    group1: string;
    group2: string;
    group3: string;
    group4: string;
    group5: string;
    group6: string;
    subGroup: string;
  };
  json: JsonData;
  resultItemsList: any[];
  resultModal: false;
  tempItemsList: ConfiguratorOption[];
  tempMultiple: boolean;
  tempSubStep: ConfiguratorSubstep[];
  tempTitle: string;
};

const firstElement = 0;

// @ts-ignore
const ProductConfigurator = ({
  configurator,
  onChangeQuantity,
  onFilterSteps,
  onConfirmSelection,
  onAddOrder,
  onToggleResultModal,
  onGetStepData,
  onSubmitResult,
  onResetConfigurator,
}: any) => {
  // NOTE - task 1 fix
  const renderStepValue = (index: number) => {
    // const status = Array.isArray(
    //   configurator.groups[Object.keys(configurator.groups)[index]]
    // )

    // if (status) {
    //   return configurator.codes[`group${index}`]
    // }

    // if (status) {
    //   return configurator.groups[Object.keys(configurator.groups)[index]].map(
    //     (item: any) => {
    //       return (
    //         <span key={`step-btn-${item.id}`}>{item.attributes.value}</span>
    //       )
    //     }
    //   )
    // }

    // TODO: refactor
    const stepValues = configurator.stepValues;
    const resultItemsList = configurator.resultItemsList;
    let result: any;

    if (index === 0 && resultItemsList.length > 0) {
      result = resultItemsList.find((item: any) => {
        return item.attributes.configurator_step_id == stepValues[index]?.step_id;
      })

      if (result) return result.attributes.code;
    }

    result = stepValues.find((v: any) => v.index === index);
    if (result) return result.value;

    return "Выбрать";

    // return (
    //   configurator.groups[Object.keys(configurator.groups)[index]].name ||
    //   'Выбрать'
    // )
  };

  const activeSteps = useMemo(() => {
    return configurator.activeSteps;
  }, [configurator]);

  const renderSteps = () => {
    return activeSteps.map((step: any, index: number) => {
      let disabled = true;

      const group = configurator.groups[Object.keys(configurator.groups)[index - 1]];

      disabled = index === 0 ? false : Array.isArray(group)? !group.length : !group.name;

      return (
        <div className="input-group" key={`group-${index}`}>
          <input
            type="text"
            className="input-configurator"
            placeholder={step.attributes.name}
            disabled={disabled}
          />
          <button
            className="btn__theme__configurator"
            onClick={() => {
              filterData(index);
              if (index < configurator.stepIndex) {
                onConfirmSelection({ stepIndex: index });
              }
            }}
            disabled={disabled}
          >
            {renderStepValue(index)}
          </button>
        </div>
      );
    });
  };

  const chooseItems = async (data: any, subData: any) => {
    const stepIndex = configurator.stepIndex;
    const currentResultItemsList = configurator.resultItemsList;
    const stepValues = configurator.stepValues;
    // if data is empty, it will be {} or []
    const isDataEmpty = Object.keys(data).length === 0;
    const isDataArray = Array.isArray(data)

    if (isDataEmpty) return;

    let resultItemsList: any[] = currentResultItemsList.filter((item: any) => {
      let dataConfiguratorStepId: string;

      if (isDataArray) {
        dataConfiguratorStepId = data[0].attributes.configurator_step_id;
      } else {
        dataConfiguratorStepId = data.attributes.configurator_step_id;
      }

      return item.attributes.configurator_step_id !== dataConfiguratorStepId
    })

    const resultItemIds = stepValues.filter(item => item.index < stepIndex)
                                    .map(item => (item.option_id))
                                    .flat()

    let notation: any;
    // clone data to overwrite value of code,
    // but don't touch original data
    let resultData: any;

    if (isDataArray) {
      const itemIds = data.map(item => item.id)
      let optionNotations: any = []

      resultItemIds.push(itemIds)
      const dependentItemIds = resultItemIds.flat()

      for (let id of itemIds) {
        notation = await optionCode(id, dependentItemIds)
        optionNotations.push(notation)
      };

      optionNotations = optionNotations.filter(item => item)

      const optionNotationIds = optionNotations.map(item => item.option_id)

      resultData = data.map((item) => {
        let itemId: number = Number(item.id);

        if (optionNotationIds.includes(itemId)) {
          let optionNotation = optionNotations.find(notation => notation.option_id === itemId)

          return {...item, attributes: {...item.attributes, code: optionNotation.code}}
        } else {
          return item
        }
      })
    } else {
      notation = await optionCode(data.id, resultItemIds)

      if (notation) {
        resultData = {...data, attributes: {...data.attributes, code: notation.code}}
      } else {
        resultData = {...data}
      }
    }

    resultItemsList.push(resultData);
    resultItemsList = resultItemsList.flat();

    let subGroup = null;

    if (!isDataArray && !isDataEmpty) {
      subGroup = subData ? subData.attributes : { id: null, name: "", code: "" };

      data = {
        id: data.id,
        name: data.attributes.value,
        code: notation?.code || data.attributes.code,
      };
    }

    const groups = {
      ...configurator.groups,
      subGroup,
      [Object.keys(configurator.groups)[stepIndex]]: data,
    };

    const selectedOptionId = data?.id || data.map((item: any) => item.id); // if checkboxes

    onGetStepData(stepIndex, groups, activeSteps, stepIndex, selectedOptionId);

    onConfirmSelection({
      resultItemsList,
      resultModal: false,
      stepIndex: configurator.stepIndex + 1,
      groups,
    });
  };

  const filterData = (stepIndex: any) => {
    const data = configurator.json.included;
    const step = activeSteps.find((_item: any, index: number) => index === stepIndex);
    const optionsList = configurator.options.reduce((acc: any[], optionItem: any) => {
      if (optionItem.attributes.configurator_step_id === step.id) {
        acc.push(optionItem);
      }

      return acc;
    }, []);

    const subSteps = step.relationships.configurator_substeps.data;

    const subStepsList = subSteps.reduce((acc: any[], subStepItem: any) => {
      const foundData = data.find(
        (el: any) => el.id === subStepItem.id && el.type === subStepItem.type
      );

      if (foundData) {
        let subOptionsList: any[] = [];

        data.forEach((el: any) => {
          foundData.relationships.configurator_options.data.forEach(
            (sub: any) => {
              if (sub.id === el.id) {
                subOptionsList.push(el);
              }
            }
          );
        });

        return [
          ...acc,
          {
            ...foundData,
            options: subOptionsList,
          },
        ];
      }
      return [...acc];
    }, []);

    onFilterSteps({
      tempTitle: step.attributes.name,
      tempMultiple: step.attributes.multiple,
      tempItemsList: optionsList,
      tempSubStep: subStepsList[0],
      resultModal: true,
    });
  };

  const onAddOrderHandler = (data: any) => {
    onAddOrder({
      resultModal: false,
      cartModal: true,
    });

    onChangeQuantity(data);
    closeResultModal("#resultModal");
    setTimeout(() => openInnerModal("#cartModal"), 700);
  };

  const renderResultModal = () => {
    if (configurator.groups.group6)
      return (
        <ModalResult
          data={configurator}
          onAddOrderHandler={onAddOrderHandler}
          onResetConfiguratorHandler={onResetConfigurator}
        />
      );
  };

  const handleClickRequest = async () => {
    const baseCode = getSerial(configurator);

    const stepsWithMod = configurator.steps.filter((step) => step.attributes.multiple === true)
    const stepModIds = stepsWithMod.map((step) => Number(step.id))

    const stepValues = configurator.stepValues.filter((stepValue) => stepModIds.includes(stepValue.step_id))
    const modIds = stepValues.map((stepValue) => stepValue.option_id).flat()

    const data = await API.submitResult(baseCode, modIds);

    onSubmitResult(data);

    closeConfigurator();
    openInnerModal("#resultModal");
  }

  const isComplete = useMemo(() => {
    if (!configurator.json || !activeSteps.length) return false;
    const lastIndex = activeSteps.length - 1;
    return (
      configurator.stepValues.findIndex((v: any) => v.index === lastIndex) !==
      -1
    );
  }, [configurator, activeSteps]);

  const resetConfigurator = () => {
    closeConfigurator();
    onResetConfigurator();
  }

  return (
    <>
      <div className="configurator">
        <div className="configurator-header navbar-theme-colored">
          <div className="configurator-header-wrap hide-elements">
            <Link to="/">
              <Logo />
            </Link>
            <button className="btn" onClick={resetConfigurator}>
              <Close />
            </button>
          </div>
        </div>
        <div className="configurator-left-column">
          <p className="configurator-hint">
            Для перехода к заявке <br />
            заполните все поля
          </p>
        </div>
        <div className="configurator-right-column">
          <h3 className="configurator-title">
            <span className="configurator-title__span">
              <Configurator />
              {configurator.confTitle || "Конфигуратор"}
            </span>
          </h3>
          <div className="configurator-inputs-container">
            <div className="configurator-serial-wrap">
              {
                configurator.filled_mask.map(el => {
                  return <span className="configurator-serial">{el}</span>;
                })
              }
            </div>

            {renderSteps()}

            <footer className="configurator-footer">
              <button
                className="btn btn__theme__request"
                onClick={handleClickRequest}
                disabled={!isComplete}
              >
                <ChevronInCircle />
                Перейти к заявке
              </button>
            </footer>
            {configurator.resultModal && (
              <ModalInConfigurator
                options={configurator}
                closeModal={onToggleResultModal}
                chosenItems={chooseItems}
              />
            )}
          </div>
        </div>
      </div>
      {renderResultModal()}
      {configurator.cartModal && <ModalCart />}
    </>
  );
};

const mapStateToProps = (store: any) => ({
  configurator: store.configurator,
});

const mapDispatchToProps = (dispatch: any) => ({
  onChangeQuantity: (id: any, amount: any) => {
    dispatch(changeQuantity(id, amount));
  },
  onFilterSteps: (data: any) => {
    dispatch(filterStepsData(data));
  },
  onConfirmSelection: (data: any) => {
    dispatch(confirmSelection(data));
  },
  onAddOrder: (data: any) => {
    dispatch(addOrder(data));
  },
  onToggleResultModal: (state: any) => {
    dispatch(toggleResultModal(state));
  },
  onGetStepData: (id: any, groups: any, steps: any, index: number, selectedOptionId: number | null) => {
    dispatch(getStepDataFx(id, groups, steps, index, selectedOptionId));
  },
  onSubmitResult: (data: any) => {
    dispatch(submitResult(data))
  },
  onResetConfigurator: () => {
    dispatch(resetConfigurator())
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductConfigurator);
